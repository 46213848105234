<template>
  <div class="work-page nike-city-fc">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/NC_1.jpg'"/>
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">CITY FC KIT LAUNCH</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Come On You City Boys, You City Girls.</h1>
  			</div>
  			<div class="col-2">
  				<p>The chant you will hear in the terraces when CITY FC play. To launch the new kit for 2018, we created a campaign that drew on the iconography and spirit of City FC.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col">
      <div class="col" style="width: 45%; margin-right: -40px; z-index: 2;">
        <img src="/static/images/NC_2.jpg"/>
      </div>
      <div class="col" style="width: 60%">
        <img src="/static/images/NC_3.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/NC_4.jpg"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img src="/static/images/NC_5.jpg" style="width: 80%; margin-top: -31%"/>
      </div>
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img style="width: 75%; margin-bottom: -100%; z-index: 3; position: relative;" src="/static/images/NC_6.jpg"/>
      </div>
    </div>

    <img src="/static/images/NC_7.jpg" style="margin-top: 25%; margin-bottom: 22%;"/>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img src="/static/images/NC_8.jpg" style="width: 70%; margin-top: -100%"/>
      </div>
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img style="width: 120%; z-index: 3; position: relative;" src="/static/images/NC_9.png"/>
      </div>
    </div>

    <div class="footer">
      <div class="prev">
        <router-link to="/nike-all-whites">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/super-netball">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'nike-city-fc',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-city-fc {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
